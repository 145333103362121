import _ from 'lodash';

import React, { useEffect, useState } from 'react'
import * as XLSX from "xlsx";
import Luckysheet from "luckysheet";

import PDFViewer from '../PDFViewer/PDFViewer';
import LoadHTMLFromExcel from '../Email/LoadEmailFromExcel';
import {
  Card, Grid, IconButton, Tab, Tabs, CircularProgress
} from '@mui/material';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import GridOnIcon from '@mui/icons-material/GridOn';
import DownloadIcon from '@mui/icons-material/Download';
import { getAttatchmentsData } from '../../../../../APICalls';
import { STORAGE_KEYS } from '../../../../../utils/utils';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AttatchmentView = ({ POPDF, excelSheets, transactionIdValue, companyName, PONumber }) => {

  const [attatchmentValue, setAttatchmentValue] = useState(0);
  const [excelSheetValue, setExcelSheetValue] = useState(0);
  const [selectedExcel, setSelectedExcel] = useState(null);
  const [loadingAttatchment, setLoadingAttatchment] = useState(false);


  useEffect(() => {
    if (selectedExcel) {
      document.getElementById("luckysheet").innerHTML = ""; // Reset Luckysheet

      Luckysheet.create({
        container: "luckysheet",
        showtoolbar: true,
        showinfobar: false,
        allowEdit: false, // 🚀 Disable editing globally
        data: selectedExcel.map((sheet) => ({
          ...sheet,
          config: {
            ...sheet.config,
            celldata: sheet.data.flatMap((row, r) =>
              row.map((cell, c) => ({
                r,
                c,
                v: { ...cell, locked: 1 }, // 🔒 Lock each cell
              }))
            ),
          },
        })),
        //  Hide row/column context menu options
        sheetFormulaBar: false, // Hide formula bar
        showContextmenu: false, // Disable right-click context menu
        showAddRow: false, // Hide add row button
        showAddCol: false, // Hide add column button
        enableAddRow: false, // Prevent appending rows
        enableAddCol: false, // Prevent appending columns
        allowInsertRow: false, //  Disable row insertion
        allowDeleteRow: false, //  Disable row deletion
        allowInsertColumn: false, //  Disable column insertion
        allowDeleteColumn: false, //  Disable column deletion
        allowInsertCell: false, //  Disable inserting cells
        allowDeleteCell: false, //  Disable deleting cells
        allowAddRow: false, //  Prevent appending rows
        allowAddColumn: false, //  Prevent appending columns
      });
    }
  }, [selectedExcel]);

  const parseExcel = (arrayBuffer) => {
    const workbook = XLSX.read(arrayBuffer, { type: "array", cellStyles: true });

    return workbook.SheetNames.map((sheetName) => {
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
      });

      let colWidths = {}; // Store max width for each column

      const formattedData = jsonData.map((row, rowIndex) =>
        row.map((cell, colIndex) => {
          const cellValue = cell || "";

          // Calculate max column width based on cell value length
          const estimatedWidth = cellValue.toString().length * 7; // Approximate character width
          colWidths[colIndex] = Math.max(colWidths[colIndex] || 50, estimatedWidth);

          return {
            v: cellValue, // Cell value
            vt: 0, // Vertical alignment
            ht: 0, // Horizontal alignment
          };
        })
      );

      return {
        name: sheetName,
        data: formattedData,
        config: {
          columnlen: colWidths, // Apply auto column widths
        },
      };
    });
  };


  const handleCallExcel = async (excelSheetValue = 0) => {
    setLoadingAttatchment(true);
    const { excelFilePath, data } = excelSheets[attatchmentValue]
    const sheetData = data['sheets'];

    const htmlValue = await getAttatchmentsData(localStorage.getItem(STORAGE_KEYS.TOKEN), transactionIdValue, 'COMPLETED', excelFilePath, true, sheetData[excelSheetValue])
    const parsedData = parseExcel(htmlValue)
    setSelectedExcel(parsedData)
    setLoadingAttatchment(false);
  }

  const handleChangeExcelSheetValue = (event, newValue) => {
    setExcelSheetValue(newValue)
    handleCallExcel(newValue);
  }

  const attatchmentValidationExcel = () => {
    return !_.isEmpty(POPDF)
      ? attatchmentValue - (POPDF.length + excelSheets[attatchmentValue]['data']['sheets'].length)
      : attatchmentValue
  }

  const showValidationComponent = (isExcel) => {
    if (isExcel) {
      return !_.isEmpty(POPDF)
        ? attatchmentValue >= POPDF.length
        : true
    } else {
      return !_.isEmpty(POPDF)
        ? attatchmentValue < POPDF.length
        : false
    }
  }

  const downloadPOPDF = () => {
    // create "a" HTML element with href to file & click
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([POPDF[attatchmentValue]], {
      type: "text/plain"
    }));
    const currentDateValue = new Date();
    // Extract date and time components
    const year = currentDateValue.getFullYear();
    const month = (currentDateValue.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = currentDateValue.getDate().toString().padStart(2, '0');
    const hours = currentDateValue.getHours().toString().padStart(2, '0');
    const minutes = currentDateValue.getMinutes().toString().padStart(2, '0');
    const seconds = currentDateValue.getSeconds().toString().padStart(2, '0');

    // Create formatted date and time strings
    const formattedDateTime = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;

    // file extension, PDF
    a.setAttribute("download", `PDF_FILE_${formattedDateTime}.pdf`);
    document.body.appendChild(a);
    a.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(a);
  }

  const donwloadExcel = () => {
    const href = URL.createObjectURL(new Blob([selectedExcel]));
    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    // file extension, XLS for excel
    link.setAttribute('download', 'AttatchmentExcel.xls');
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  const handleChangeattatchmentValue = (event, newValue) => {
    if (event?.target?.innerText?.includes("EXCEL")) {
      setAttatchmentValue(POPDF.length + newValue);
    } else {
      setAttatchmentValue(newValue);
    }
  };

  const downloadAttatchment = () => {
    if (showValidationComponent(false) && !_.isEmpty(POPDF)) {
      return (
        <>
          <IconButton
            onClick={downloadPOPDF}
            edge="end"
            color="primary"
          >
            <DownloadOutlinedIcon />
          </IconButton>
        </>
      )
    };
    if (showValidationComponent(true) && !_.isEmpty(excelSheets)) {
      return (
        <>
          <IconButton
            onClick={donwloadExcel}
            edge="end"
            color="primary"
          >
            <DownloadIcon />
          </IconButton>
        </>
      )
    }
  }

  const checkAttatchmentComponent = () => {
    if (showValidationComponent(false) && !_.isEmpty(POPDF)) {
      return (
        <>
          <PDFViewer
            rowFile={POPDF[attatchmentValue]}
            isInput
          />
        </>
      )
    };
    if (showValidationComponent(true) && !_.isEmpty(excelSheets)) {
      return (
        <>
          <div>
            {selectedExcel && (
              <div id="luckysheet" style={{ width: "100%", height: "500px" }}></div>
            )}
          </div>
        </>
      )
    }
  }

  useEffect(() => {
    if (excelSheets && !_.isEmpty(excelSheets)) {
      handleCallExcel(0)
    }
  }, [])

  return (
    <div>
      <Grid
        container
      >
        <Grid item xs={12} sm={12} md={12}>
          <Tabs
            value={attatchmentValue}
            onChange={handleChangeattatchmentValue}
            variant="scrollable"
            scrollButtons
          >
            {!_.isEmpty(POPDF) && (
              POPDF.map((element, key) => (
                <Tab
                  icon={<PictureAsPdfIcon />}
                  iconPosition="start"
                  label={
                    <span>
                      {`PDF # ${key + 1}`}
                      {downloadAttatchment()}
                    </span>}
                  {...a11yProps(0)}
                />
              ))
            )}
            {!_.isEmpty(excelSheets) && (
              excelSheets.map((element, key) => (
                <Tab
                  icon={<GridOnIcon />}
                  iconPosition="start"
                  label={
                    <span>
                      {`Excel # ${key + 1}`}
                      {downloadAttatchment()}
                    </span>
                  }
                  {...a11yProps(1)}
                />
              ))
            )}
          </Tabs>
          {!_.isEmpty(excelSheets) && (
            <Tabs
              value={excelSheetValue}
              onChange={handleChangeExcelSheetValue}
              variant="scrollable"
              scrollButtons
            >
              {excelSheets[attatchmentValue]['data']['sheets'].map((element, key) => {
                return (
                  <Tab
                    icon={<GridOnIcon />}
                    iconPosition="start"
                    label={
                      <span>
                        {element}
                        {downloadAttatchment()}
                      </span>
                    }
                    {...a11yProps(1)}
                  />
                )
              }
              )}
            </Tabs>
          )}
          <Card
            style={{ height: '800px', overflow: 'auto' }}
          >
            {loadingAttatchment
              ? <Grid
                container
                justifyContent="center"
                paddingTop="3%"
              >
                <Grid item xs={12}>
                  <CircularProgress color="info" />
                </Grid>
                <Grid item xs={12}>
                  <h2>
                    Loading data, please wait...
                  </h2>
                </Grid>
              </Grid>
              : checkAttatchmentComponent()}
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default AttatchmentView