import React, { useEffect, useState } from 'react'
import {
    Modal, Typography, Card, CardContent, TextField,
    CardActions, Button, Grid, AppBar, Toolbar, InputAdornment
} from '@mui/material';

import ContactMailIcon from '@mui/icons-material/ContactMail';
import KeyIcon from '@mui/icons-material/Key';
import Select from 'react-select';

import { STORAGE_KEYS } from '../../../../../helpers/helpers';
import { inviteUsers, getCheckToken } from '../../../../../APICalls';

const InviteNewUser = ({
    openModal, handleCloseModal, token
}) => {
    const [email, setEmail] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [responseError, setResponseError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [userTokenData, setUserTokenData] = useState(null);
    const [permissionsSelect, setPermissionsSelect] = useState([]);
    const validationCallAPI = async () => {
        const { status, data } = await getCheckToken(localStorage.getItem(STORAGE_KEYS.TOKEN));
        if (status === 200) {
            const { permissions } = data
            const keysPermissions = Object.keys(permissions).filter((element) => element !== "mass_approval")
            setUserTokenData(keysPermissions);
        }
    }

    const handleOnChange = (value) => {
        setPermissionsSelect(value);
    }

    const handleEmail = (event) => {
        setEmail(event.target.value)
    }

    const parsePermissions = () => {
        return permissionsSelect.map((element) => element.value)
    }

    const handleInviteUser = async () => {
        setEmailError(null);
        setSuccessMessage(null);
        setResponseError(null);
        const parsedPermissions = parsePermissions();
        if (email) {
            const { status, data } = await inviteUsers(token, email, parsedPermissions);
            if (status === 200) {
                setSuccessMessage("User invited succesfully!");
            } else {
                setResponseError(data.message);
            }
        } else {
            setEmailError('Missing email field. Please Check.');
        }
    }

    useEffect(() => {
        validationCallAPI();
    }, [])

    return (
        <Modal
            open={openModal}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grid
                container
                justifyContent="center"
            >
                <Card
                    style={{
                        width: "60%",
                    }}
                >
                    <AppBar position="relative">
                        <Toolbar className="toolbarStyle">
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <h2>
                                        Invite new user to your organization
                                    </h2>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <CardContent>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={3}
                            paddingBottom={15}
                            paddingTop={3}
                        >
                            <Grid item xs={12}>
                                <Grid
                                    container
                                >
                                    <Grid item xs={12}>
                                        <p>Please write the email of the user you want to invite to your organization:</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="email"
                                            name="email"
                                            size="small"
                                            placeholder='User Email'
                                            onChange={handleEmail}
                                            value={email}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className="text-primary"
                                                    >
                                                        {<ContactMailIcon />}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <p>Select the access permissions for the new user:</p>
                                    </Grid>
                                    <Grid item xs={12} style={{ zIndex: 1000 }}>
                                        {userTokenData && (
                                            <Select
                                                isMulti
                                                menuPlacement="bottom"
                                                options={userTokenData.map((element) => {
                                                    return { label: element, value: element }
                                                })}
                                                placeholder={
                                                    <Grid container spacing={1} alignItems="center" style={{ textAlign: "left" }}>
                                                        <Grid item >
                                                            <KeyIcon />
                                                        </Grid>
                                                        <Grid item >
                                                            Permissions for new user:
                                                        </Grid>
                                                    </Grid>
                                                }
                                                onChange={handleOnChange}
                                                style={{ width: '100%', textAlign: 'left' }}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                value={permissionsSelect}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {emailError && (
                            <p
                                style={{
                                    color: "red",
                                    paddingTop: "5%"
                                }}
                            >
                                Error: {emailError}
                            </p>
                        )}
                        {successMessage && (
                            <p
                                style={{
                                    color: "green",
                                    paddingTop: "5%"
                                }}
                            >
                                {successMessage}
                            </p>
                        )}
                        {responseError && (
                            <p
                                style={{
                                    color: "red",
                                    paddingTop: "5%"
                                }}
                            >
                                {responseError}
                            </p>
                        )}
                    </CardContent>
                    <CardActions
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            size="medium"
                            variant="contained"
                            color='error'
                            onClick={handleCloseModal}
                        >
                            Close
                        </Button>
                        <Button
                            size="medium"
                            variant="contained"
                            color='info'
                            onClick={handleInviteUser}
                        >
                            Invite User
                        </Button>
                    </CardActions>
                </Card >
            </Grid>
        </Modal >
    )
}

export default InviteNewUser;
